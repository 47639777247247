import React from 'react';
import { Container, Typography } from '@mui/material';

const NotFound: React.FC = () => {
  return (
    <Container 
        sx={{
            ml: '0.4rem',
            mr: '50px',
            mb: '150px',
            width: '90vw'
        }}
    >
    <Typography variant="h1">404, Not Found</Typography>
    <Typography variant="h3">
        This might be a problem on our end, but we couldn't find that page. Hopefully the links below
        have what you're looking for.
    </Typography>
    </Container>
  );
};

export default NotFound;
